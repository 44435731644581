import DepartureProvider, {
	QUERY_PARAM_DEPARTURE_ID,
	useDeparture,
} from '@/components/ui-logic/providers/departure/departure'
import { useMemo } from 'react'
import { useProductContext } from '@bluheadless/ui-logic/src/providers/product'
import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { useProductOptionsForQueryParams } from '@bluheadless/ui-logic/src/hooks/product/useProductOptionsForQueryParams'
import theme from '@/theme'

const WithDepartureData = ({ Tile, priceProps: _priceProps, ...props }) => {
	const [departure] = useDeparture()

	const [
		{
			product: { id = '', sku = '', attributes = {}, shortDescription = '', configurableOptions = [], urlKey } = {},
			selectedOptionsIdsValues,
		},
	] = useProductContext()

	const productOptionsForQueryParams = useProductOptionsForQueryParams({
		selectedOptionsIdsValues,
		configurableOptions,
	})

	const urlParams = useMemo(
		() => ({
			...productOptionsForQueryParams,
			[QUERY_PARAM_DEPARTURE_ID]: departure?.entity_id,
		}),
		[productOptionsForQueryParams, departure?.entity_id]
	)

	const urlWithDeparture = useCatalogUrl(urlKey, urlParams)

	const priceProps = useMemo(
		() => ({
			..._priceProps,
			oldPrice: departure?.price ?? _priceProps.oldPrice,
			middlePrice: departure?.price ?? _priceProps.middlePrice,
			amount: departure?.price ?? _priceProps.amount,
			specialAmount: departure?.special_price ?? _priceProps.specialAmount,
		}),
		[_priceProps, departure?.price, departure?.special_price]
	)

	props.shortDescription = shortDescription || props.description || ''
	props.location =
		(attributes.city?.value ? (attributes.city?.value || '') + ' - ' : '') +
		(attributes.region?.value ? (attributes.region?.value || '') + ' - ' : '') +
		(attributes.destination?.value || '')

	const plusLabels = []
	const plusItems = attributes?.labels?.value || []
	const servicesItems = attributes?.services || []

	if (Array.isArray(plusItems)) {
		plusItems.forEach((label) => {
			plusLabels.push({
				color: theme.palette.primary.dark,
				id: 'plus_label',
				label: label,
				textColor: '#FFFFFF',
			})
		})
	}

	props.id = id
	props.sku = sku
	props.services = servicesItems

	props.plusLabels = {
		position: 'left',
		labels: plusLabels,
	}

	props.hotelData = attributes.hotel_data ? JSON.parse(attributes.hotel_data?.value) : {}
	props.descriptionTitle = attributes?.description_title?.value

	return <Tile {...props} url={urlWithDeparture} priceProps={priceProps} />
}

const TileMiddleware = (props) => {
	return (
		<DepartureProvider>
			<WithDepartureData {...props} addToCartProps={null} />
		</DepartureProvider>
	)
}

export default TileMiddleware
